import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import Section from '~components/Section'

const ImageSection = ({ className, image, color, size }) => {
	return (
		<Wrap className={className} color={color} padding={size === 'small'}>
			{ size === 'large' ? 
				<LargeImage image={image}/>
				:
				<Section>
					<SmallImage image={image}/>
				</Section>	
			}
		</Wrap>
	)
}

const Wrap = styled.div`
	background-color: ${props => props.color ? `var(--${props.color === 'pink' ? 'light-pink' : props.color})` : 'var(--light-pink)'};
	padding: ${props => props.padding ? '150px 0' : '0'};
	${mobile}{
		padding: ${props => props.padding ? '80px 0' : '0'};
	}
`
const LargeImage = styled(Image)`
	
`
const SmallImage = styled(Image)`
	grid-column: span 12;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
`
ImageSection.propTypes = {
	className: PropTypes.string,
	image: PropTypes.object,
	color: PropTypes.string,
	size: PropTypes.string,
}

export default ImageSection